// types
import type { NewsArticle } from "$types/types";
import type { Terminal } from "$types/types";

// slugify
export function slugify(string: string) {
	return string.replace(/\W/g, "-").replace(/--/g, "-").toLowerCase();
}

export function deslugify(string: string) {
	const words = string.split(/-/g);
	const titleCase = words.map((w) => {
		return w.charAt(0).toUpperCase() + w.slice(1);
	});
	const output = titleCase.join(" ");
	return output;
}

export function toCamelCase(string: string) {
	return string
		.replace(/[^a-z0-9]/gi, " ")
		.toLowerCase()
		.split(" ")
		.map((el, ind) =>
			ind === 0 ? el : el[0].toUpperCase() + el.substring(1, el.length),
		)
		.join("");
}

// parse Markdown
export function parseMarkdown(string: string) {
	const sp = '<p class="mb-4">';
	const ep = "</p>";
	let output: string = sp;
	output += string.replace(/\n/g, `${ep}${sp}`);
	output += ep;
	return output;
}

// parse rich text
export function parseRichText(string: string) {
	let output = string;

	//- add margins below paragraph
	output = output.replace(/<p>/gi, '<p class="mb-4">');

	//- lists
	output = output.replace(/<ul>/gi, '<ul class="list-disc pl-4 mb-8">');
	output = output.replace(/<li>/gi, '<li class="mb-3 text-[.95em]">');

	//- tables
	output = output.replace(
		/<table>/gi,
		'<table class="table-auto border-collapse mb-4">',
	);
	output = output.replace(
		/<td>/gi,
		'<td class="px-2 py-3 border border-black text-[13px] leading-snug align-top lg:text-sm">',
	);

	//- style h2 tag
	output = output.replace(
		/<h2>/gi,
		'<h2 class="mt-8 mb-8 font-medium text-2xl">',
	);

	//- style h3 tag
	output = output.replace(
		/<h3>/gi,
		'<h3 class="mt-6 mb-4 font-medium text-lg">',
	);

	//- style h4 tag
	output = output.replace(/<h4>/gi, '<h4 class="mb-2 sm:text-lg">');

	//- style h5 tag
	output = output.replace(/<h5>/gi, '<h5 class="mb-2 text-16">');

	//- style anchor tags
	output = output.replace(
		/<a/gi,
		'<a class="underline underline-offset-4 hover:text-primary-dark outline-none focus:text-primary-dark focus:decoration-primary-dark transition-all"',
	);

	//- replace <strong> transform-gpu
	output = output.replace(/<strong>/gi, '<div class="font-semibold">');
	output = output.replace(/<\/strong>/gi, "</div>");

	//- remove extraneous non-breaking spaces
	output = output.replace(/\s{2,}/gi, " ");

	return output;
}

// sort articles
// sorted by query in hygraph
// export function sortArticles(articles: NewsArticle[]): NewsArticle[] {
// 	const sorted = articles.sort((a: NewsArticle, b: NewsArticle) => {
// 		const numA = new Date(b.publicationDate).getTime();
// 		const numB = new Date(a.publicationDate).getTime();
// 		return numA - numB;
// 	});
// 	return sorted;
// }

// build map link
export function buildMapLink(terminal: Terminal) {
	const ta = terminal.address;
	const tm = terminal.map;
	const base = "https://www.google.com/maps/place/";
	const url = tm.shortLink
		? tm.shortLink
		: `${base}${ta.street},+${ta.city},+${ta.state}+${ta.postalCode}/@${tm.latitude},${tm.longitude}`;
	return url ? url : "";
}
